import React from "react"
import { Routes, Route, useNavigate } from 'react-router-dom'

import Greet from "./Navigations/Greet/Greet"
import Register from "./Navigations/Register/Register"
import SignIn from "./Navigations/SignIn/SignIn"
import NotFound from "./Navigations/NotFound/NotFound"

import WebDrive from "./Navigations/FunctionPages/WebDrive/WebDrive"
import Menu from "./Navigations/Menu/Menu"

export default function App() {
  const navigate = useNavigate()
  
  return (
      <>
          <Routes>
            <Route path='/'>
              <Route index element={<Greet />} />
              <Route path='Greet' element={<Greet />} />
              <Route path='Menu' element={<Menu />} />


              {/* <Route path='WebDrive' element={<WebDrive />} /> */}
              
              {/* <Route path='Auth'>
                <Route index element={<SignIn />} />
                <Route path='SignIn' element={<SignIn />} />
                <Route path='Register' element={<Register />} />
              </Route>

              <Route path='Admin'>
                <Route index element={<Greet />} />
                <Route path='Home' element={<Greet />} />
              </Route> */}

            </Route>

            <Route path='*' element={<NotFound />} />
          </Routes>
      </>
  )
}

function PromiseHandler(promise){ 
    return promise
    .then((content) => [null, content])
    .catch((error) => Promise.resolve([error, null]));
};


const API_Path = window.location.protocol + "//" + window.location.hostname + ":30010"

export { PromiseHandler, API_Path }
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Greet.css'
import "../../Components/StyleSheets/status.css"
import "../../Components/StyleSheets/cards.css"
import "../../Components/StyleSheets/lists.css"
import RandomDotBackgroud from '../../Components/RandomDotBackgroud/RandomDotBackgroud';

export default function Greet() {
  const navigate = useNavigate()

  // useEffect(()=>{
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  const HandleStart = ()=>{
    navigate("/Menu")
  }

  return (
    <div id='HomePageBody'>
      <div id='Home-greet'>
        <div className='basicIntro white-blur-card bg-switch disableSelect' style=
          {{
            "--white-card-height":"600",
            "--white-card-basis":"300",
            "--white-card-grow":"1"
          }}>
            <h2 className='header'>
              KiHackerTech
            </h2>
            <div className='body'>
              <p>
                我是一個北科大電機系學生，我建立這個網站的用意，是提供有應用服務以及算力需求的使用者部分權限來存取我寫的程式
              </p>
              <p>
                另一方面也給自己一個更美化的介面來承載我那得用指令行操作的程式。因為資安的考量，請見諒有一部分功能無法提供所有人使用。
              </p>
            </div>
        </div>
        <div className='Intro white-blur-card disableSelect' style=
          {{
            "--white-card-height":"600",
            "--white-card-basis":"400",
            "--white-card-grow":"2"
          }}>
          <p>
            我很重視資安，此網站使用下列多項技術用來確保重要資料以及敏感訊息不受到威脅:
          </p>
          <ul className='emph-list'>
            <li>
              [ TLS ] 傳輸層安全性協議，資料傳輸前經過加密
            </li>
            <li>
              [ SHA256 ] 密碼進行雜湊演算，使用者密碼不會有任何機會被明文傳輸或儲存
            </li>
          </ul>
          <p style={{"textIndent": 0}}>
            如果你準備好了，點擊開始即可進入應用服務選單。
          </p>
          <div className='btn-style btn-solid' onClick={HandleStart} style={{"--btn-color": "rgb(60 217 198)"}}>開始</div>
        </div>
      </div>
      <RandomDotBackgroud amount={20} min_radius={3} max_raius={30} min_blur={80} max_blur={200}/>
    </div>
  );    

}


// Lorem default floor element let Outlet id floor setAPI_TOKEN amount default console.warn warn Outlet ,
//           className navigate max_blur key hooks
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Menu.css'
import RandomDotBackgroud from '../../Components/RandomDotBackgroud/RandomDotBackgroud';
import RollShellSurroundedIcon from '../../Components/RollShellSurroundedIcon/RollShellSurroundedIcon';

export default function Menu() {
  const navigate = useNavigate()

  // useEffect(()=>{
    
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  const setting_RollShellSurroundedIcon = {
    Icon:"folder_data",
    sideLen: "80px",
    fontSize: "2.2rem",

    textColor:"rgb(0, 29, 76)",
    bgColor:"rgb(244, 244, 244)",
    ShellColor_small:"rgb(255, 0, 0)",
    ShellColor_big:"rgb(244, 244, 244)"
  }

  function NavBack(){
    navigate(-1)
  }

  return (
    <div id='MenuPageBody'>
      <div className='header'>
        <div className="back_icon" onClick={NavBack}><i className='material-symbols-outlined'>arrow_back</i></div>
      </div>
      <div className='body'>
        <RollShellSurroundedIcon setting_RollShellSurroundedIcon={setting_RollShellSurroundedIcon} />
      </div>
      <RandomDotBackgroud amount={10} min_radius={3} max_raius={20} min_blur={80} max_blur={200}/>
    </div>
  );    

}


// Lorem default floor element let Outlet id floor setAPI_TOKEN amount default console.warn warn Outlet ,
//           className navigate max_blur key hooks
import axios from 'axios'
import { API_Path } from '../General'

const unAuthAPI = axios.create({
    baseURL: API_Path + "/API/WebDrive/",
})

unAuthAPI.interceptors.request.use((config)=>{

    return config
}, (err)=>{
    return Promise.reject(err);
})

const AuthAPI = axios.create({
    baseURL: API_Path + "/API/WebDrive/",
})

AuthAPI.interceptors.request.use((config)=>{
    let TOKENs = localStorage.getItem("TOKEN")
    if(!TOKENs) throw new Error('Token Not Exist');

    TOKENs = JSON.parse(TOKENs)

    const ACCESS_TOKEN = TOKENs.ACCESS_TOKEN.AccessTOKEN
    config.headers.setAuthorization("Bearer " + ACCESS_TOKEN)

    return config
}, (err)=>{
    return Promise.reject(err);
})



export { unAuthAPI, AuthAPI }
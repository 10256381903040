import './NotFound.css'

export default function NotFound() {
  return (
    <div className='NotFoundPageBody'>
      <h2 className='text_center'>404 Not Found</h2>
      <hr/>
      <h3 className='text_center'>很抱歉...您請求的資源似乎不在此處或移至其他位址。</h3>
      <br/>
      <h3 className='text_center'>Apologies for you seeing this. This resource seems to not exist or may be located elsewhere.</h3>
    </div>
  );
}

import axios from 'axios'

const API_Path = window.location.protocol + "//" + window.location.hostname + ":30010"

const AuthAPI = axios.create({
    baseURL: API_Path + "/API/Auth",
    // headers: {
    //     "Content-Type": "application/json",
    //     "Accept": "application/json",
    //     "Authorization": `Bearer ${token}`,
    // }
})

export { AuthAPI }
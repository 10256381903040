
import "./RandomDotBackgroud.css"


export default function RandomDotBackgroud(props){

    const {
      amount,
      min_radius = 4,
      max_raius,
      min_blur = 3,
      max_blur,
    } = props

    let Dots = []
    for (let index = 0; index < amount; index++) {
      const raius = min_radius + Math.floor(Math.random() * (max_raius-min_radius));
      const position_right =  Math.floor(Math.random() * 100);
      const position_bottom =  Math.floor(Math.random() * 100);
      
      const R =  Math.floor(Math.random() * 255);
      const G =  Math.floor(Math.random() * 255);
      const B =  Math.floor(Math.random() * 255);
      
      const blur =  min_blur + Math.floor(Math.random() * (max_blur-min_blur));
      
      Dots.push(<div key={index} className='color-dot' style={
        {
          "--color_dot_raius": raius,
          "--colro_dor_position_right" : position_right,
          "--colro_dor_position_bottom" : position_bottom,
          "--color_dot_color": "rgb(" + R + ", " + G + ", " + B  + ")",
          "--colro_dor_max_blur" : blur,

        }} />)
      
    }

    return(
      <div className='RandomDotBackgroud'>
        {Dots}
      </div>
      
    )
}
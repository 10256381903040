
import { useEffect, useState } from "react"
import "./RollShellSurroundedIcon.css"


export default function RollShellSurroundedIcon(props){

  const [StartDeg, setStartDeg] = useState(0)

  const { Icon, sideLen, fontSize,
    textColor,
    bgColor,
    ShellColor_small,
    ShellColor_big
  } = props?.setting_RollShellSurroundedIcon

  useEffect(()=>{
    setStartDeg(()=>{return 1 + Math.floor(Math.random() * 359)})
  }, [])

  return(
    <div className='RollShellSurroundedIcon' style={
      {
        "--sideLen": sideLen,
        "--fontSize": fontSize,
        
        "--textColor": textColor,
        "--bgColor": bgColor,
        "--ShellColor_small": ShellColor_small,
        "--ShellColor_big": ShellColor_big

      }}>
      <i className="material-symbols-outlined">{Icon}</i>

      <div className="Shell-container" style={{"--startDeg": StartDeg}}>
        <div className="cercle cercle-1"></div>
        <div className="cercle cercle-2"></div>
      </div>
    </div>
    
  )
}